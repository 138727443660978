import PricingElement from './PricingElement';
import { homePricingContent } from './homePricingContent';
import SectionTitle from 'components/SectionTitle';

const ComputingPricing = () => {
  return (
    <div className="pricing" id="pricing">
      <SectionTitle
        header="home.pricing.header"
        description="home.pricing.description"
      />
      <div className="pricing-examples">
        {homePricingContent.map((instance, index) => (
          <PricingElement key={index} instance={instance} />
        ))}
      </div>
    </div>
  );
};

export default ComputingPricing;

import SmLogoLeft from 'images/svg/smLogo/SmLogoLeft';
import SmLogoRight from 'images/svg/smLogo/SmLogoRight';
import { useTranslation } from 'next-i18next';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

const StartComputing = () => {
  const { t } = useTranslation();

  return (
    <section className="start-computing">
      <div className="start-computing__content">
        <div className="start-computing__background-left">
          <SmLogoLeft />
        </div>
        <h3 className="start-computing__header">
          {t('startComputing.header')}
        </h3>
        <div className="start-computing__links-wrapper">
          <ButtonRegisterOrLogin
            page="home"
            className="start-computing__sign-up btn btn-type-white"
          />
        </div>
        <div className="start-computing__background-right">
          <SmLogoRight />
        </div>
      </div>
    </section>
  );
};

export default StartComputing;

import Video from 'components/Video';
import SectionTitle from 'components/SectionTitle';
import { useTranslation } from 'next-i18next';

const HomeVideo = () => {
  const { t } = useTranslation();

  return (
    <div className="home-video">
      <SectionTitle
        header={t('home.howToRent.header')}
        description={t('home.howToRent.description')}
      />
      <Video videoSrc="https://www.youtube.com/embed/Y3iroGDIeZM" />
    </div>
  );
};

export default HomeVideo;

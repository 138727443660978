import { NvidiaLogo } from 'images/svg';

export const homePricingContent = [
  {
    Logo: NvidiaLogo,
    prices: [
      {
        type: 'RTX 4090',
        gb: '24',
        price: '0.23',
      },
      {
        type: 'RTX 3060',
        gb: '8',
        price: '0.05',
      },
      {
        type: 'A4000',
        gb: '16',
      },
      {
        type: 'H100',
        gb: '80',
      },
    ],
    opacity: false,
  },
  // {
  //   header: 'RTX 4090',
  //   Logo: NvidiaLogo,
  //   prices: [
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //   ],
  //   opacity: true,
  // },
  // {
  //   header: 'RTX 4090',
  //   Logo: NvidiaLogo,
  //   prices: [
  //     {
  //       gb: '24',
  //       price: '0.30',
  //     },
  //     // {
  //     //   gb: '24',
  //     //   price: '0.5',
  //     // },
  //     // {
  //     //   gb: '24',
  //     //   price: '0.5',
  //     // },
  //   ],
  //   opacity: false,
  // },
  // {
  //   header: 'RTX 4090',
  //   Logo: NvidiaLogo,
  //   prices: [
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //     {
  //       gb: '24',
  //       price: '0.5',
  //     },
  //   ],
  //   opacity: true,
  // },
];

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';
import ImageWrapper from 'components/ImageWrapper';

const images = [
  {
    className: 'main-images__connection',
    src: '/img/smComputingScreens/computing-main-connection.webp',
    width: 335,
    height: 261,
  },
  {
    className: 'main-images__system',
    src: '/img/smComputingScreens/computing-main-system.webp',
    width: 300,
    height: 258,
  },
];

const HomeMainImages = () => {
  return (
    <>
      <div className="content-images">
        {images.map((image, index) => (
          <ImageWrapper
            key={index}
            className={image.className}
            src={image.src}
            width={image.width}
            height={image.height}
            alt="placeholder"
            priority
          />
        ))}
      </div>
      <div className="content-images-slider">
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          wrapperClass="swiper-wrapper"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="content-images-slider__slide">
              <ImageWrapper
                key={index}
                className={image.className}
                src={image.src}
                width={image.width}
                height={image.height}
                alt="placeholder"
                priority
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default HomeMainImages;

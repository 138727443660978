import FeatureCard from 'components/FeatureCard';
import { presentationFeatures } from './homeFeaturesPresentation';
import SectionTitle from 'components/SectionTitle';

const HomeFeaturesPresentation = () => {
  return (
    <div className="features-presentation" id="features">
      <SectionTitle
        header="home.features.header"
        description="home.features.description"
      />
      {presentationFeatures.map((feature, index) => (
        <FeatureCard
          key={index}
          invertPosition={index % 2 != 0}
          feature={feature}
        />
      ))}
    </div>
  );
};

export default HomeFeaturesPresentation;

import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { getPublicApiUrl } from 'services/EnvProvider';

const HomeHeroNavigation = () => {
  const apiUrl = getPublicApiUrl();

  const { t } = useTranslation();

  const homeHeroNavigation = [
    { title: t('home.pageHero.smallNavigation.features'), href: '#features' },
    { title: t('home.pageHero.smallNavigation.pricing'), href: '#pricing' },
    {
      title: t('footnotes.apiLink'),
      href: `${apiUrl}/docs`,
    },
  ];

  return (
    <ul className="main-section-navigation">
      {homeHeroNavigation.map((option, index) => (
        <li key={index} className="page-hero__small-nav-item">
          {option.href?.startsWith('#') ? (
            <Link href={option.href} legacyBehavior>
              <a>{option.title}</a>
            </Link>
          ) : (
            <a href={option.href} target="_blank" rel="noopener noreferrer">
              {option.title}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

export default HomeHeroNavigation;

import HomeMainSection from 'components/Layout/Home/HomeMainSection';
import HomeFeaturesPresentation from 'components/Layout/Home/HomeFeaturesPresentation';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import HomePricing from 'components/Layout/Home/HomePricing';
import HomeVideo from 'components/Layout/Home/HomeVideo';
import { NextPageContext } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import StartComputing from 'components/Layout/Home/StartComputing';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('home.meta.title')}</title>
        <meta name="keywords" content={t('home.meta.keywords')} />
        <meta name="description" content={t('home.meta.description')} />
      </Head>
      <HomeMainSection />
      <HomeVideo />
      <HomePricing />
      <HomeFeaturesPresentation />
      <StartComputing />
    </>
  );
};

export async function getStaticProps(context: NextPageContext) {
  const { locale = 'en' } = context;

  return {
    props: await serverSideTranslations(locale),
  };
}

export default Home;

import PageHeroBackground from 'components/Layout/PageHeroBackground';
import HomeHeader from './HomeContent/HomeHeader';
import Images from './HomeContent/HomeMainImages';
import HomeHeroNavigation from './HomeHeroNavigation';
import InstanceBrands from './InstanceBrands';
import GlowingSquare from 'components/Layout/GlowingSquare';

const HomeMainSection = () => {
  return (
    <div className="main-section-wrapper">
      <GlowingSquare
        parentClassName="main-section-wrapper"
        squareSideLengthRelativeToScreen={50}
        positionVertical="bottom"
        positionHorizontal="center"
      />
      <PageHeroBackground>
        <div className="main-section">
          <HomeHeroNavigation />
          <div className="main-section__body">
            <div className="main-section__body-content">
              <HomeHeader />
              <Images />
            </div>
            <InstanceBrands />
          </div>
        </div>
      </PageHeroBackground>
    </div>
  );
};

export default HomeMainSection;

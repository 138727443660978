import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';

type PricePerGb = {
  type: string;
  gb: string;
  price?: string;
};

interface Instance {
  header?: string;
  Logo: any;
  prices: PricePerGb[];
  opacity: boolean;
}

interface Props {
  instance: Instance;
}

const PricingElement = (props: Props) => {
  const {
    instance: { Logo, prices, opacity },
  } = props;

  const { t } = useTranslation();

  const classes = classNames('pricing-examples__element', {
    'pricing-examples__element--opacity': opacity,
  });

  return (
    <div className={classes}>
      <div className="pricing-examples__element-header">
        <p>{t('home.pricing.gpuModels.title')}</p>
        <Logo />
      </div>
      <hr />
      <div className="pricing-examples__element-prices">
        {prices.map((price, index) => (
          <div className="pricing-examples__element-prices-single" key={index}>
            <div className="pricing-examples__element-type-and-size">
              <p>{price.type}</p>
              <p>
                {t('home.pricing.element.prices.diskSize', {
                  diskSize: price.gb || '-',
                })}
              </p>
            </div>
            <p>
              {price.price
                ? t('home.pricing.element.prices.usdPerHour', {
                    usdPerHour: price.price,
                  })
                : t('home.pricing.comingSoon')}
            </p>
          </div>
        ))}
      </div>
      <div className="pricing-examples__element-message">
        <Trans i18nKey="home.pricing.message">
          More GPUs are coming soon. Need a different GPU? Just reach out to
          <Link
            className="pricing-examples__element-message-link link-type-underline"
            href="/support"
          >
            our support team
          </Link>
          !
        </Trans>
      </div>
    </div>
  );
};

export default PricingElement;

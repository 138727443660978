interface Props {
  videoSrc: string;
}

const Video = (props: Props) => {
  const { videoSrc } = props;

  return (
    <div className="video-wrapper">
      <iframe className="video" src={videoSrc} allowFullScreen />
    </div>
  );
};

export default Video;

import { instanceBrands } from './instanceBrands';

const InstanceBrands = () => {
  return (
    <div className="main-section-brands">
      {instanceBrands.map((brand, index) => (
        <brand.Logo key={index} />
      ))}
    </div>
  );
};

export default InstanceBrands;

import {
  TensorflowLogo,
  PytorchLogo,
  DockerLogo,
  JupyterLogo,
} from 'images/svg';

export const instanceBrands = [
  {
    Logo: TensorflowLogo,
  },
  {
    Logo: PytorchLogo,
  },
  {
    Logo: DockerLogo,
  },
  {
    Logo: JupyterLogo,
  },
];
